jQuery(function () {
	APP.init();

	// init
	accordionInit();
	countUpInit();
	lozadInit();
	loadMoreInit();
	fancyboxInit();
	formInit();

	menuSpyInit();
	toggleMenuSpy();

	jQuery(".share-facebook").on('click', function (e) {
		e.preventDefault();
		var url = jQuery(this).attr('href');
		window.open(url, "popupWindow", "width=660,height=480,scrollbars=yes");
		return false;
	});
});

jQuery(window).on('scroll', function () {
	APP.fixed();
});

// variable
var header = jQuery("header"),
	backdrop = jQuery(".backdrop"),
	backToTop = jQuery(".back-to-top"),
	buttonMenu = jQuery("#buttonMenu"),
	mobileWrap = jQuery("header .mobile-wrap"),
	buttonSearch = jQuery("header .button-search"),
	searchWrap = jQuery(".search-wrap"),
	heightHeader = jQuery("header").height(),
	heightWindow = jQuery(window).height(),
	widthWindow = jQuery(window).width(),
	outerHeightWindow = jQuery(window).outerHeight();

// toggleText
$.fn.extend({
	toggleText: function (a, b) {
		return this.text(this.text() == b ? a : b);
	},
});

var APP = {
	fixed: () => {
		// header
		jQuery(window).scrollTop() > heightHeader
			? header.addClass("active")
			: header.removeClass("active");
		jQuery(window).scrollTop() > (outerHeightWindow - heightHeader)
			? backToTop.addClass("active")
			: backToTop.removeClass("active");
	},
	backdrop: () => {
		backdrop.on('click', function () {
			jQuery(this).removeClass('open');
			buttonMenu.removeClass('open'); // remove button menu header
			mobileWrap.slideUp(200); // remove mobile wrap
		})
	},
	backToTop: () => {
		backToTop.on('click', function () {
			jQuery('html, body').animate({ scrollTop: 0 }, 500);
		})
	},
	mapping: () => {
		const mainMenu = jQuery("header .navbar-nav").mapping({
			mobileWrapper: "header .mobile-wrap",
			mobileMethod: "prependTo",
			desktopWrapper: "header .button-search",
			desktopMethod: "insertBefore",
			breakpoint: 1279.98,
		});
	},
	toggleDropdown: () => {
		const dropdown = jQuery(".dropdown"),
			dropdownToggle = jQuery(".dropdown-toggle"),
			dropdownMenu = jQuery(".dropdown-menu");
		dropdownToggle.on("click", function () {
			jQuery(this).toggleClass("active");
			jQuery(this).parents(dropdown).find(dropdownMenu).toggleClass("open");
		});
	},
	toggleMenu: () => {
		buttonMenu.on("click", function () {
			jQuery(this).toggleClass("open");
			mobileWrap.slideToggle();
			backdrop.toggleClass('open');
		});
	},
	toggleSearch: () => {
		buttonSearch.on("click", function () {
			jQuery(this).toggleClass("open");
			searchWrap.toggleClass("open");
		});
	},
	setBackground: () => {
		jQuery("[setBackground]").each(function () {
			jQuery(this).css(
				"background-image",
				"url(" + jQuery(this).attr("setBackground") + ")"
			).addClass('bg-cover bg-no-repeat');
		});
	},
	sourceInfo: () => {
		console.log(
			"%cJNT",
			"font-size:100px;color:#2E8DC1;text-shadow:0 1px 0 #276F97,0 2px 0 #276F97 ,0 3px 0 #276F97 ,0 4px 0 #276F97 ,0 5px 0 #276F97 ,0 6px 1px rgba(0,0,0,.1),0 0 5px rgba(0,0,0,.1),0 1px 3px rgba(0,0,0,.3),0 3px 5px rgba(0,0,0,.2),0 5px 10px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.2),0 20px 20px rgba(0,0,0,.15);"
		);
		console.log(
			"%c JNT %c - JNT in Viet Nam",
			"border-radius: 2px; padding: 3px; background: #2E8DC1; color: #000",
			"color: #2E8DC1"
		);
	},
	init: () => {
		APP.backdrop();
		APP.backToTop();
		APP.mapping();
		APP.toggleDropdown();
		APP.toggleMenu();
		APP.toggleSearch();
		APP.setBackground();
		APP.sourceInfo();
	}
}
const homeBannerSlider= new Swiper(".home-banner .swiper", {
	// loop: true,
	// autoplay: {
	// 	delay: 5000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	spaceBetween: 30,
	speed: 1000,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	effect: 'fade',
	fadeEffect: {
		crossFade: true
	},
	navigation: {
		nextEl: ".home-banner .button-next",
		prevEl: ".home-banner .button-prev",
	},
	pagination: {
		el: ".home-banner .swiper-pagination",
		clickable: true,
	},
});

const homePromotionSlider= new Swiper(".home-promotion .swiper", {
	// loop: true,
	// autoplay: {
	// 	delay: 5000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	spaceBetween: 30,
	speed: 1000,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-promotion .button-next",
		prevEl: ".home-promotion .button-prev",
	},
	pagination: {
		el: ".home-promotion .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		1280: {
			slidesPerView: 2,
			spaceBetween: 30,
		},
	},
});

const homeCorporatePromotionSlider= new Swiper(".home-corporate-service .swiper", {
	// loop: true,
	// autoplay: {
	// 	delay: 5000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	spaceBetween: 30,
	speed: 1000,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-corporate-service .button-next",
		prevEl: ".home-corporate-service .button-prev",
	},
	pagination: {
		el: ".home-corporate-service .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
			spaceBetween: 15,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		1280: {
			slidesPerView: 3,
			spaceBetween: 30,
		},
	},
});

const homeEventNewsSlider= new Swiper(".home-event-news .swiper", {
	// loop: true,
	// autoplay: {
	// 	delay: 5000,
	// 	disableOnInteraction: false,
	// 	pauseOnMouseEnter: true,
	// },
	spaceBetween: 30,
	speed: 1000,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-event-news .button-next",
		prevEl: ".home-event-news .button-prev",
	},
	pagination: {
		el: ".home-event-news .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
			spaceBetween: 15,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 20,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		1280: {
			slidesPerView: 3,
			spaceBetween: 30,
		},
	},
});

const homePartnersSlider= new Swiper(".home-partners .swiper", {
	// loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	slidesPerView: 3,
	spaceBetween: 20,
	grid: {
		rows: 2,
	},
	speed: 1000,
	observer: true,
	observeParents: true,
	lazy: {
		loadPrevNext: true,
	},
	navigation: {
		nextEl: ".home-partners .button-next",
		prevEl: ".home-partners .button-prev",
	},
	pagination: {
		el: ".home-partners .swiper-pagination",
		clickable: true,
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
		},
		768: {
			slidesPerView: 3,
		},
		1024: {
			slidesPerView: 3,
		},
		1280: {
			slidesPerView: 4,
		},
	},
});

const defaultSwiper = new Swiper(".default .swiper", {
	loop: true,
	autoplay: {
		delay: 5000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	speed: 1000,
	// spaceBetween: 12,
	// observer: true,
	// observeParents: true,
	// lazy: {
	// 	loadPrevNext: true,
	// },
	// navigation: {
	// 	nextEl: ".default .button-next",
	// 	prevEl: ".default .button-prev",
	// },
	// pagination: {
	// 	el: ".default .swiper-pagination",
	// 	clickable: true,
	// },
	// breakpoints: {
	// 	768: {
	// 		slidesPerView: 2,
	// 	},
	// 	1024: {
	// 		slidesPerView: 3,
	// 	},
	// 	1280: {
	// 		slidesPerView: 4,
	// 	},
	// },
});

function lozadInit() {
	// Initialize library to lazy load images
	const observer = lozad('.lozad', {
		threshold: 0.1,
		// enableAutoReload: true,
	})

	// Picture observer
	// with default `load` method
	const pictureObserver = lozad('.lozad-picture', {
		threshold: 0.1
	})

	// Background observer
	// with default `load` method
	const backgroundObserver = lozad('.lozad-background', {
		threshold: 0.1
	})

	observer.observe()
	pictureObserver.observe()
	backgroundObserver.observe()
}

function fancyboxInit() {
	Fancybox.bind("[data-fancybox]", {
		showLoading: true,
		preload: true,
		infinite: false,
		// parentEl: document.forms[0], // Element containing main structure
		mainClass: "fancybox-wrapper", // Custom class name or multiple space-separated class names for the container
	});

	Fancybox.bind("a.popup-link", {
		showLoading: true,
		type: "iframe",
		preload: true,
	});

	Fancybox.bind('[data-fancybox="single"]', {
		groupAttr: false,
	});

	jQuery(".btn-close-fancybox").on("click", function () {
		Fancybox.close();
	});
}

function formInit() {
	if (jQuery('#fileCv')) {
		jQuery('#fileCv').on('change', function () {
			jQuery('#uploadName').text(this.files[0].name);
		});
	}
}

function equalHeight(el) {
	var height = 0;
	jQuery(el).each(function () {
		var thisHeight = jQuery(this).height();
		if (thisHeight > height) {
			height = thisHeight;
		}
		setTimeout(() => {
			jQuery(el).height(height)
		}, 100)
	})
}

function checkCookie(cookieName) {
	let cookie = getCookie(cookieName);
	jQuery('.exp a').on('click', function () {
		var dataExp = jQuery(this).data('exp');
		setCookie("exp", dataExp, 1);
	});
	if (cookie != "") {
	} else {
	}
}

function countStart(el, num) {
	const options = {
		separator: '.',
		enableScrollSpy: true, // if true -> turn off countUp.start() after first scroll spy check
		scrollSpyDelay: 500, // delay for scroll spy check
		scrollSpyOnce: true,
	};

	let countUp = new CountUp(el, num, options);

	if (!countUp.error) {
		// countUp.start(() => console.log('Complete!'));
	} else {
		console.error(countUp.error);
	}
}

function countUpInit() {
	const elNumber = jQuery(".number-count");
	if (elNumber)
		elNumber.each(function (index) {
			let dataCount = jQuery(elNumber[index]).data('count');
			countStart(elNumber[index], dataCount);
		})
}

function loadMoreInit() {
	jQuery('.load-more-wrap').each(function () {
		var button = jQuery(this).find('.btn-load-more');
		var contentItem = jQuery(this).find('.load-more-item');
		var contentItemLength = contentItem.length;
		var contentItemShow = jQuery(this).data('show');
		var contentItemLoad = jQuery(this).data('load');
		contentItem.slice(contentItemShow, contentItemLength).addClass('hidden');
		button.on('click', function (e) {
			e.preventDefault();
			console.log('load more click');
			var $thisLoadMore = jQuery(this).parents('.load-more-wrap');
			var visibleItem = $thisLoadMore.find('.load-more-item:visible').length;
			var hiddenItem = $thisLoadMore.find('.load-more-item:hidden').length;
			contentItem.slice(0, (visibleItem + contentItemLoad)).slideDown();
			if (hiddenItem <= contentItemLoad) {
				jQuery(this).hide();
			}
		})
	});
	// move html
	jQuery('.news-detail-page .recruitment-html-box').insertBefore('.recruitment-detail-section .recruitment-other-box');
}

function accordionInit() {
	jQuery('.accordion-item').on('click', function () {
		jQuery(this)
			.toggleClass('active')
			.siblings().removeClass('active')
		jQuery(this)
			.find('.accordion-header').toggleClass('active')
			.parents('.accordion-item')
			.siblings().find('.accordion-header').removeClass('active')
		jQuery(this)
			.find('.accordion-content').slideToggle()
			.parents('.accordion-item')
			.siblings().find('.accordion-content').slideUp('active')
	});

	jQuery('.accordion-list').each(function () {
		jQuery(this).find('.accordion-item').first().trigger('click');
	});
}

function menuSpyInit() {
	if (jQuery('.navbar-spy').length) {
		jQuery('html, body').addClass('scroll-smooth');
		var elm = document.querySelector('.navbar-spy');

		var lavalampElm = document.querySelector('.lavalamp');
		var positionLavalamp = function (activeElm) {
			if (widthWindow >= 1280) {
				lavalampElm.style.width = activeElm.elm.offsetWidth + 'px';
				lavalampElm.style.left = activeElm.elm.offsetLeft + 'px';
			}
		};
		var ms = new MenuSpy(elm, {
			threshold: 190,
			callback: positionLavalamp,
		});
		positionLavalamp({ elm: elm.querySelector('li.active') });
	}
}

function toggleMenuSpy() {
	jQuery('.navbar-spy .navbar-toggle').on('click', function () {
		jQuery(this).toggleClass('active');
		jQuery(this).parents('.navbar-spy').find('.navbar-menu').slideToggle();
	});
	jQuery('.navbar-spy .navbar-menu li a').on('click', function () {
		if (widthWindow < 1024) {
			jQuery(this).parents('.navbar-spy').find('.navbar-toggle').removeClass('active');
			jQuery(this).parents('.navbar-spy').find('.navbar-menu').slideUp();
		}
	});
}
